@import "../../assets/scss/theme.scss";

// .apexcharts-canvas {
//   text {
//     fill: $text-muted !important;
//   }
// }

.card-header-101-1 {
    font-size: 18px;
    color: #666;
}
.card-header-101-1-1 {
    font-size: 16px;
    color: #666;
}
.card-number-101-2 {
    font-size: 30px;
    color: #212179;
}
.card-number-101-2-1 {
    font-size: 22px;
    color: #212179;
}
.text-style-101-3{
    padding: 0px 8px;
}
.space-15 {
    margin: 15px;
}
.custombox-101-22 {
    margin: 0 0 0 35px;
}
.custombox-101-22-1 {
    margin: 0 0 0 55px;
}
.custombox-101-23 {
    border-right: 1px solid #ccc;
    padding: 30px 0px;
}
.custombox-101-23-1 {
    padding: 30px 0px;
}
.border-bottom{
    border-bottom: 1px solid #aaa;
    margin-bottom: 10px;
    padding: 37px 0;
}
.padding-left-10{
    padding-left: 10px;
}

.space-2232{
    padding:10px;
    margin-bottom: 5px;
    border-bottom:1px solid #eee; ;
}

.chart-22334{
    padding: 20px;
}