/* Web-Application View */

.contact-info-container {
  display: flex;
  justify-content: center;
  padding-bottom: 100px;
  background: #FBFFF5;
}

.contact-info-body {
  display: flex;
  border: 1px solid #ccc;
  border-radius: 10px 0px 0px 10px;
  margin-top: 42px;
}

.contact-info-details-container {
  background: #0f461e;
  padding: 45px;
  flex: 1;
  display: flex;
  border-radius: 10px 0px 0px 10px;
}

.contact-info-block {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.contact-info-header {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  margin: 10px;
  color: #fff;
}

.contact-info-info-container {
  display: flex;
  margin: 24px;
}

.contact-info-info-icon {
  margin-right: 24px;
}

.contact-info-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #fff;
}

.contact-info-details {
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.05em;
  color: #fff;
  margin-bottom: 30px;
}

.follow-us-block {
  text-align: center;
}

.contact-info-sub-header {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  margin-bottom: 17px;
  opacity: 0.8;
  margin-top: 154px;
}

.contact-info-container-social-icon {
  display: flex;
  justify-content: center;
}

.icon-section {
  padding: 5px;
}

.contact-info-form-container {
  padding: 45px;
  flex: 1;
  background: #fff;
}

.contact-info-form-section {
  margin-top: 20px;
}

.contact-info-form-input-container {
  padding: 10px 0;
}

.contact-info-form-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.85);
}

.contact-info-form-input {
  padding: 8px 12px;
  width: 95%;
  height: 30px;
  background: #ffffff;
  border: 1px solid rgba(9, 9, 9, 0.3);
  border-radius: 2px;
}

.contact-info-button-block {
  display: flex;
}

.contact-info-form-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 30px;
  gap: 11px;
  margin-top: 50px;
  flex: 1;
  cursor: pointer;
  height: 45px;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  background: #209541;
  border-radius: 4px;
  border: none;
}

.contact-info-form-input input {
  border: none;
  outline: none;
  border-left: 1px solid #ccc;
  margin-left: 10px;
}

@media (max-width: 950px) {
  @media (max-width:430px) {
    .contact-info-details-container {
      padding: 14px 4px;
    }

    .contact-info-info-container {
      margin: 10px;
    }

    .contact-info-sub-header {
      display: none;
    }

    .contact-info-container-social-icon {
      display: none;
    }

    .contact-info-form-container {
      background-color: #FBFFF5;
      border: 1px solid #ccc;
    }

    .contact-info-form-input {
      background-color: #FBFFF5;
    }

    .contact-info-form-input input {
      background-color: #FBFFF5;
    }

    .contact-info-details-container {
      border-radius: 10px 10px 0px 0px;
    }

    .contact-info-body {
      border: none;
    }
  }

  @media (max-width:375px) {
    .contact-info-body {
      width: 306px;
    }
  }

  .contact-info-body {
    flex-direction: column;
  }
}